<div class="shared-branch-selector-input-container" (click)="branchInput.focus(); openComplete()">
  <button (click)="onClose($event)" type="button" class="shared-branch-selector-input-icon p-2">
    <shared-icon class="text-[#AEB7C1]" icon="magnify" [size]="32"></shared-icon>
  </button>
  <input
    #branchInput
    class="shared-branch-selector-input"
    [class.shared-branch-selector-opend]="autocompleteComponent?.opend"
    uiInput
    type="text"
    [placeholder]="placeholder"
    [ngModel]="query$ | async"
    (ngModelChange)="onQueryChange($event)"
    (keyup)="onKeyup($event)"
  />
  <button class="shared-branch-selector-clear-input-icon pr-2" *ngIf="(query$ | async)?.length > 0" type="button" (click)="clear()">
    <shared-icon class="text-[#1F466C]" icon="close" [size]="32"></shared-icon>
  </button>
</div>
<ui-autocomplete class="shared-branch-selector-autocomplete z-modal w-full">
  <hr class="ml-3 text-[#9CB1C6]" *ngIf="autocompleteComponent?.opend" uiAutocompleteSeparator />
  <p *ngIf="(filteredBranches$ | async)?.length > 0" class="text-p2 p-4 font-normal" uiAutocompleteLabel>Filialvorschläge</p>
  <button
    class="shared-branch-selector-autocomplete-option min-h-[44px]"
    [class.shared-branch-selector-selected]="value && value.id === branch.id"
    (click)="setBranch(branch)"
    *ngFor="let branch of filteredBranches$ | async"
    [uiAutocompleteItem]="branch"
  >
    <span class="text-lg font-semibold">{{ store.formatBranch(branch) }}</span>
  </button>
</ui-autocomplete>
