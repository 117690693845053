import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OpenStreetMapParams } from './openstreetmap.params';
import { PlaceDto } from './defs';

@Injectable({ providedIn: 'root' })
export class OpenStreetMap {
  url = 'https://nominatim.openstreetmap.org/';

  constructor(private httpClient: HttpClient) {}

  query(query: OpenStreetMapParams.Query) {
    return this.httpClient.get<PlaceDto[]>(`${this.url}search`, {
      params: {
        format: 'json',
        ...query,
      },
    });
  }
}
